import { AxiosError } from 'axios';

import {
  ErrorResponse,
  HttpStatus,
  RequestHeaders,
  RequestInterceptor,
  ResponseInterceptor,
} from '@/app/types/api/config/config';
import { StoreCommit } from '@/app/types/store/store';

import cookies from '@/plugins/cookies';
import i18n from '@/plugins/i18n';
import Store from '@/store';

import ErrorResponseInterceptorNotify, {
  InterceptorNotifyName,
} from '@/app/api/factories/configs/ErrorResponseInterceptorNotify';
import env from '@/app/env/env';
import ClientResponseError from '@/app/errors/ClientResponseError';
import logoutAndRedirect from '@/app/helpers/logoutAndRedirect';

const getToken = () => {
  const token = cookies.get(env.cookieName);

  return token ? 'Bearer ' + token : '';
};

function handleAuthError(error: AxiosError<ErrorResponse>) {
  if (error?.response?.status === HttpStatus.Unauthorized) {
    logoutAndRedirect();
  }
}

function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message =
    error.response?.data?.message ??
    i18n.global.t('errors.standardServerError');
  const errors = error.response?.data?.errors;

  switch (error?.response?.status) {
    case HttpStatus.PaymentRequired:
      return [i18n.global.t('errors.paymentFailed')];
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message];
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')];
    default:
      return [message];
  }
}

function setMaintenanceStatus(responseStatus: HttpStatus | undefined) {
  if (responseStatus === HttpStatus.ServiceUnavailable) {
    Store.commit(StoreCommit.SetServerStatus, responseStatus);
  }
}

const requestInterceptors: RequestInterceptor = {
  onSuccess(config) {
    const { state } = Store;
    // org uid
    const orgId = state.organizations.currentOrganizationUID;
    // auth token
    const token = config?.headers?.Authorization;
    const isTokenExpired = token !== getToken();

    if (config.headers && (!token || isTokenExpired)) {
      config.headers['Authorization'] = getToken();
    }

    if (config.headers && orgId) {
      config.headers[RequestHeaders.OrganizationId] = orgId;
    }

    return config;
  },
  onError(error) {
    return Promise.reject(error);
  },
};

const responseInterceptors: ResponseInterceptor = {
  onSuccess(response) {
    return response;
  },
  onError(error: AxiosError<ErrorResponse>): Promise<ClientResponseError> {
    handleAuthError(error);

    const responseStatus: HttpStatus | undefined = error.response?.status;

    setMaintenanceStatus(responseStatus);

    const messages = getErrorMessages(error);
    const notify = new ErrorResponseInterceptorNotify(
      i18n.global.t,
      error,
      messages,
    );

    notify.create();

    const isTwoFactorAuth =
      notify.notifyName === InterceptorNotifyName.TwoFactorAuthorization;

    return Promise.reject(
      new ClientResponseError({
        name: error.name,
        message: error.message,
        status: error.response?.status,
        errors: error.response?.data?.errors ?? null,
        isTwoFactorAuth,
        notify: notify.options,
        messages,
      }),
    );
  },
};

export default {
  baseURL: env.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
  },
  timeout: 20000,
  requestInterceptors,
  responseInterceptors,
};
